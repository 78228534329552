import { useSite } from '@unisporkal/sites';
import { QUERY_TYPE } from '../../actions/types';
import useBaseQuery from './useBaseQuery.ts';
import useServiceClient from '../useServiceClient';

const useHeaderPropsQuery = () => {
  const { headerDataService } = useServiceClient();
  const site = useSite();

  return useBaseQuery(QUERY_TYPE.HEADER_DATA, async () => {
    if (site.nickname === 'getty') {
      return headerDataService.fetchGettyHeaderData();
    }
    return headerDataService.fetchHeaderData();
  });
};

export default useHeaderPropsQuery;
