import { useQuery } from '@tanstack/react-query';

type Options = {
  enabled?: boolean;
  useErrorBoundary?: boolean;
  suspense?: boolean;
};

const useBaseQuery = (queryKey, fetch, options: Options = {}) => {
  const isEnabled = () => {
    if (options.enabled != null) {
      return options.enabled;
    }
    return true;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useQuery<any>(queryKey, fetch, {
    ...options,
    enabled: isEnabled(),
  });
};

export default useBaseQuery;
